import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaConfig } from './meta-config';
import { I18nService } from './i18n.service';
import { TranslationModeEnum } from '../enums/translation-mode-enum';

/**
 * Servicio para actualizar metadatos.
 */
@Injectable({
  providedIn: 'root',
})
export class UpdateMetaService {
  /**
   * Constructor.
   * @param meta
   * @param title
   * @param i18nService
   */
  constructor(
    private meta: Meta,
    private title: Title,
    private i18nService: I18nService
  ) { }

  /**
   * Actualiza los metadatos.
   * @param componentKey
   */
  updateMetaTags(componentKey: string): void {
    const config = MetaConfig[componentKey];
    if (config) {
      const titleKey = this.i18nService.getText(
        config.title,
        TranslationModeEnum.PLACEHOLDER
      );
      const descriptionKey = this.i18nService.getText(
        config.description,
        TranslationModeEnum.PLACEHOLDER
      );
      const robotsKey = this.i18nService.getText(
        config.robots,
        TranslationModeEnum.PLACEHOLDER
      );

      if (titleKey !== '--') {
        this.title.setTitle(titleKey);
      }
      if (descriptionKey !== '--') {
        this.meta.updateTag({ name: 'description', content: descriptionKey });
      }
      if (robotsKey !== '--') {
        this.meta.updateTag({ name: 'robots', content: robotsKey });
      }
    } else {
    }
  }
}
