import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ComponentsEnum } from '../../../commons/enums/components-enum';
import { WindowService } from '../../../commons/services/window.service';
import { I18nService } from '../../../commons/services/i18n.service';
import { TranslationModeEnum } from '../../../commons/enums/translation-mode-enum';
import { filter, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

/**
 * Componente home.
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit, AfterViewInit {
  /**
   * Variable con el i18n a utilizar.
   */
  text = 'home_Data3';
  /**
   * Array con las letras del texto.
   */
  lettersArray: string[] = [];
  /**
   * Listado de componentes disponibles.
   */
  componentsEnum: typeof ComponentsEnum = ComponentsEnum;
  /**
   * Listado de componentes cargados.
   */
  loadedComponents: Set<ComponentsEnum> = new Set();
  /**
   * Modos de traducción disponibles.
   */
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;
  /**
   * Suscripción.
   */
  routeSubscription!: Subscription;
  /**
   * Controla la animación del botón.
   */
  @ViewChild('animatedButton') animatedButton!: ElementRef;
  /**
   * @ignore
   */
  private scrollHandler!: () => void;

  /**
   * Constructor
   * @param windowService
   * @param renderer
   * @param i18nService
   * @param router
   */
  constructor(
    private windowService: WindowService,
    private renderer: Renderer2,
    private i18nService: I18nService,
    private router: Router,
  ) { }

  /**
   * @ignore
   */
  ngOnInit(): void {
    const rawText = this.i18nService.getText(this.text, this.translationModes.REMOVE_TAGS);
    this.lettersArray = rawText.split('').map(char => (char === ' ' ? '&nbsp;' : char));
    this.makeRouteSubscriptions();
    this.goUp();

    if (this.windowService.isBrowser) {
      this.scrollHandler = this.onScroll.bind(this);
      window.addEventListener('scroll', this.scrollHandler);
    }
  }

  /**
   * @ignore
   */
  ngAfterViewInit(): void {
    if (this.windowService.isBrowser) {
      const button = this.animatedButton.nativeElement;
      const spans = button.querySelectorAll('span');
      spans.forEach((span: HTMLElement, index: number) => {
        // const delay = index * 0.01;
        const delay = 0;
        this.renderer.setStyle(span, 'animation-delay', `${delay}s`);
      });
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  /**
   * Limpia loadedComponents cuando el scroll alcanza la posición superior.
   */
  private onScroll(): void {
    if (this.windowService.isBrowser && window.scrollY === 0) {
      this.loadedComponents.clear();
      window.removeEventListener('scroll', this.scrollHandler);
    }
  }

  /**
   * Hace las subscripciones.
   */
  private makeRouteSubscriptions() {
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.goUp();
      });
  }

  /**
   * Busca el componente opportunity y hace scroll hasta él.
   */
  goToOpportunity(): void {
    this.loadComponent(this.componentsEnum.PARADOX);
    this.loadComponent(this.componentsEnum.EXPERIMENT);
    this.loadComponent(this.componentsEnum.WHAT);
    this.loadComponent(this.componentsEnum.HOW);
    this.loadComponent(this.componentsEnum.ALLY);
    this.loadComponent(this.componentsEnum.OPPORTUNITY);

    setTimeout(() => {
      if (this.windowService.isBrowser) {
        const section = document.getElementById('opportunity');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, 10);
  }

  /**
   * Carga el componente seleccionado.
   * @param componentType
   */
  loadComponent(componentType: ComponentsEnum): void {
    this.loadedComponents.add(componentType);
  }

  /**
   * Revisa si el componente está cargado.
   * @param componentType
   * @returns
   */
  isComponentLoaded(componentType: ComponentsEnum): boolean {
    return this.loadedComponents.has(componentType);
  }

  /**
   * hace scroll hasta arriba.
   */
  goUp(): void {
    if (this.windowService.isBrowser) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
