<div class="paradox-container">
  <h2 class="title hst" [innerHTML]="'home_Data4' | i18n"></h2>
  <div class="dual-container">
    <div class="content issue">
      <div class="title" [innerHTML]="'home_Data5' | i18n"></div>
      <div class="msg" [innerHTML]="'home_Data7' | i18n"></div>
    </div>
    <div class="content solution">
      <div class="title" [innerHTML]="'home_Data6' | i18n"></div>
      <div class="msg" [innerHTML]="'home_Data8' | i18n"></div>
    </div>
  </div>
  <div class="gallery"></div>
  <img class="icon warn-icon" src="/assets/images/paradox/warn.svg" alt="Warn icon">
  <img class="icon ok-icon" src="/assets/images/paradox/ok.svg" alt="Ok icon">
</div>
