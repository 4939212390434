export const TOS_COOKIES_I18N_ENTRIES = {
  tos_cookies_Data1: {
    es: '<p>Política de cookies</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data2: {
    es: '<p>Volver</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data3: {
    es: '<p>Fecha de entrada en vigor: %X%</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data4: {
    es: '<p>Bienvenido a SmartMakings.La web SmartMakings(https://smartmakings.com/) es propiedad de la empresa Smartmakings, S.L. ("nosotros", "nuestro" o "la Empresa") que utiliza cookies y tecnologías similares en su sitio web para mejorar la experiencia del usuario y proporcionar servicios personalizados. Esta Política de Cookies tiene como objetivo informarte sobre el uso de cookies en el sitio web de SmartMakings y cómo puedes controlar su uso. Al continuar utilizando nuestro sitio web, aceptas nuestro uso de cookies de acuerdo con esta Política.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data5: {
    es: '<p>1.¿Qué son las cookies?</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data6: {
    es: '<p>Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo (computadora, tableta, teléfono móvil, etc.) cuando visitas un sitio web. Estos archivos contienen información que se utiliza para recordar tus preferencias, realizar un seguimiento de tu actividad en el sitio y mejorar tu experiencia de usuario.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data7: {
    es: '<p>2.Aceptación de la Política de Cookies</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data8: {
    es: '<p>Cuando visitas nuestro sitio web por primera vez, te pediremos tu consentimiento para utilizar cookies no esenciales. Puedes retirar tu consentimiento en cualquier momento siguiendo las instrucciones proporcionadas en la sección "Control de cookies" a continuación.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data9: {
    es: '<p>3.Tipos de cookies que utilizamos</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data10: {
    es: '<p>A continuación, se describen los tipos de cookies que utilizamos en nuestro sitio web:<br><br>3.1&emsp;Según la entidad que las gestione:<br><br>Cookies propias: Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el titular del Site y desde que se presta el servicio solicitado por el usuario. Utilizamos cookies propias para mejorar la personalización.<br><br>Utilizamos las siguientes cookies propias: </p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data11: {
    es: '<p>CookiesRead</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data12: {
    es: '<p>%X% días</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data13: {
    es: '<p>Guarda si se ha leído el pop-up de las cookies al entrar en smartmakings.com</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data14: {
    es: '<p>smartmakings-acceptedLogin</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data15: {
    es: '<p>Guarda el token de inicio de sesión.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data16: {
    es: '<p>Smartmakings</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data17: {
    es: '<p>Guarda el token de inicio de sesión.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data18: {
    es: '<p>Cookies de tercero: Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el titular del Site, sino por otra entidad que trata los datos obtenidos a través de las cookies.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data19: {
    es: '<p>__utma</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data20: {
    es: '<p>Se usa para distinguir usuarios y sesiones.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data21: {
    es: '<p>__utmt</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data22: {
    es: '<p>%X% minutos</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data23: {
    es: '<p>Se usa para limitar el porcentaje de solicitudes.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data24: {
    es: '<p>__utmb</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data25: {
    es: '<p>Se usa para determinar nuevas sesiones o visitas.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data26: {
    es: '<p>__utmz</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data27: {
    es: '<p>Almacena la fuente de tráfico o la campaña que explica cómo ha llegado el usuario al sitio.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data28: {
    es: '<p>__utmv</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data29: {
    es: '<p>Se usa para almacenar datos de variables personalizadas de visitante</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data30: {
    es: '<p>__utmx</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data31: {
    es: '<p>Se usa para determinar la inclusión de un usuario en un experimento.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data32: {
    es: '<p>_utmxx</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data33: {
    es: '<p>Se usa para determinar la caducidad de los experimentos en los que se ha incluido a un usuario.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data34: {
    es: '<p>_gaexp</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data35: {
    es: '<p>Se usa para determinar la inclusión de un usuario en un experimento y la caducidad de los experimentos en los que participa.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data36: {
    es: '<p>_glc_au</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data37: {
    es: '<p>Toma la información de los clics en los anuncios y la almacena en una cookie de origen para que las conversiones se puedan atribuir fuera de la página de destino</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data38: {
    es: '<p>_clck</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data39: {
    es: '<p>Persiste el ID de usuario y las preferencias de Clarity, exclusivos de ese sitio que se atribuyen al mismo ID de usuario.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data40: {
    es: '<p>_clsk</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data41: {
    es: '<p>Conecta varias páginas vistas por parte de un usuario en una única grabación de sesión de Clarity.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data42: {
    es: '<p>1.1&emsp;Según su finalidad<br><br>a)&emsp;Cookies esenciales: Estas cookies son necesarias para que el sitio web funcione correctamente. Incluyen cookies que te permiten iniciar sesión en áreas seguras de nuestro sitio web y utilizar funciones básicas como la navegación y el acceso a áreas seguras. Sin estas cookies, el usuario no podría recibir correctamente nuestros contenidos y servicios. Estas cookies no almacenan ninguna información de identificación personal.<br><br>b)&emsp;Cookies de rendimiento: Estas cookies recopilan información sobre cómo los usuarios utilizan nuestro sitio web, como las páginas que visitan con mayor frecuencia y si experimentan algún error. Esta información nos ayuda a mejorar la funcionalidad y el rendimiento de nuestro sitio web. Si no permite utilizar estas cookies, no sabremos cuándo visitó nuestro sitio y no podremos evaluar si funcionó correctamente.<br><br>c)&emsp;Cookies de funcionalidad: Estas cookies se utilizan para recordar las elecciones que haces y personalizar tu experiencia en nuestro sitio web. Esto puede incluir recordar tu nombre de usuario, idioma preferido y región. Si no permite utilizar estas cookies, es posible que algunos de estos servicios no funcionen correctamente.<br><br>d)&emsp;Cookies de marketing y publicidad: Trabajamos con terceros para mostrar anuncios relevantes en nuestro sitio web y en otros sitios web. Estas cookies se utilizan para rastrear tu actividad en línea y mostrar anuncios que pueden ser de tu interés. Si no permite utilizar estas cookies, verá menos publicidad dirigida.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data43: {
    es: '<p>4. Duración de las cookies</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data44: {
    es: '<p>Las cookies pueden tener diferentes duraciones. Algunas cookies se eliminan automáticamente cuando cierras tu navegador (cookies de sesión), mientras que otras permanecen en tu dispositivo durante un período de tiempo específico (cookies persistentes).</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data45: {
    es: '<p>5. Control de cookies</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data46: {
    es: '<p>Puedes gestionar y controlar las cookies de varias maneras, incluyendo:<br>a)&emsp;Configuración del navegador: La mayoría de los navegadores te permiten rechazar o aceptar cookies. Consulta la sección de ayuda de tu navegador para obtener información sobre cómo cambiar la configuración de cookies:<br><br>·&emsp;<a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">Deshabilitar cookies en Google Chrome</a><br>·&emsp;<a href="https://support.microsoft.com/es-es/windows/administrar-cookies-en-microsoft-edge-ver-permitir-bloquear-eliminar-y-usar-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Deshabilitar cookies en Microsoft Edge</a><br>·&emsp;<a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es" target="_blank">Deshabilitar cookies en Firefox</a><br>·&emsp;<a href="https://support.apple.com/es-es/105082" target="_blank">Deshabilitar cookies en Safari</a><br><br>b)&emsp; Herramientas de terceros: Puedes utilizar herramientas de terceros, como complementos de navegador o aplicaciones de seguridad, para controlar las cookies y la privacidad en línea.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data47: {
    es: '<p>6.Cambios en la Política de Cookies</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data48: {
    es: '<p>Nos reservamos el derecho de actualizar esta Política de Cookies en cualquier momento para reflejar cambios en nuestras prácticas de cookies. Te recomendamos que revises esta página periódicamente para estar informado sobre cualquier cambio importante.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data49: {
    es: '<p>7. Contacto</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data50: {
    es: '<p>Si tienes alguna pregunta o inquietud sobre nuestra Política de Cookies, no dudes en contactarnos a través de la siguiente información de contacto:<br><br>SmartMakings, S.L.<br>Torre Siglo XXI.Paseo Fluvial 15, Planta 11ª Oficina Suroeste, Badajoz, España.<br>+ 34 924 103 227<br><a href="mailto:info@smartmakings.com">info@smartmakings.com</a><br><br>Esta Política de Cookies se encuentra en conformidad con las regulaciones de privacidad aplicables y se mantiene actualizada para reflejar nuestras prácticas actuales. Puedes obtener más información en <a href="/privacy">la política de privacidad</a></p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data51: {
    es: '<p>COOKIE</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data52: {
    es: '<p>DURACIÓN</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  tos_cookies_Data53: {
    es: '<p>DESCRIPCIÓN</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
};
