import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslationModeEnum } from '../../../../commons/enums/translation-mode-enum';
import { MatDialogRef } from '@angular/material/dialog';
import { ContactRemoteService } from '../../../../commons/services/api/contact-remote.service';

/**
 * Componente formulario de contacto para empresas.
 */
@Component({
  selector: 'app-company-contact-form',
  templateUrl: './company-contact-form.component.html',
  styleUrl: './company-contact-form.component.scss'
})
export class CompanyContactFormComponent implements OnInit {
  /**
   * Formulario.
   */
  companyContactForm: FormGroup;
  /**
   * Boolean para controlar cuando se envía el form.
   */
  submitted: boolean = false;
  /**
   * Enumerador con las opciones de traducción.
   */
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;
  /**
   * Boolean para mostrar el mensaje de enviado correctamente.
   */
  formSendSuccessfully: boolean = false;
  /**
   * Boolean para el error del formulario.
   */
  formSendError: boolean = false;

  /**
   * Constructor.
   * @param dialog
   * @param fb
   * @param contactService
   */
  constructor(
    private dialog: MatDialogRef<CompanyContactFormComponent>,
    private fb: FormBuilder,
    private contactService: ContactRemoteService
  ) { }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.createForm();
    setTimeout(() => {
      this.dialog.addPanelClass('animate');
    }, 100);
  }

  /**
   * Crea el formulario.
   */
  createForm(): void {
    this.companyContactForm = this.fb.group({
      companyName: ['', Validators.required],
      responsiblePerson: ['', Validators.required],
      position: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{9,15}$')]],
      terms: [false, Validators.requiredTrue],
      newsletter: [false],
    });
  }

  /**
   * Método para manejar el envío del formulario.
   */
  onSubmit(): void {
    if (this.formSendSuccessfully) {
      this.close();
      return;
    }
    this.submitted = true;
    if (this.companyContactForm.valid) {
      const formData = new FormData();
      const formValues = this.companyContactForm.value;
      if (formValues.companyName) {
        formData.append("company_name", formValues.companyName);
      }
      if (formValues.responsiblePerson) {
        formData.append("contact_name", formValues.responsiblePerson);
      }
      if (formValues.position) {
        formData.append("contact_role", formValues.position);
      }
      if (formValues.email) {
        formData.append("email", formValues.email);
      }
      if (formValues.phone) {
        formData.append("phone", formValues.phone);
      }

      formData.append("newsletter", formValues.newsletter ? '1' : '0');

      this.contactService.sendContactForm(formData).subscribe({
        next: (success) => {
          this.formSendSuccessfully = true;
        },
        error: (error) => {
          console.log(error);
          this.formSendError = true;
        },
      });
    }
  }

  /**
   * Vuelve al formulario.
   */
  retry(): void {
    this.formSendError = false;
  }

  /**
   * Cierra el dialog.
   */
  close(): void {
    this.dialog.removePanelClass('animate');
    setTimeout(() => {
      this.dialog.close();
    }, 500);
  }
}
