import { StorageService } from "./storage.service";

/**
 * Servicio localstorage.
 */
export abstract class BaseStorageService<T> {
  /**
   * Constructor.
   * @param storageService
   */
  protected constructor(
    protected storageService: StorageService
  ) { }

  /**
   * Guarda en localstorage.
   * @param value
   */
  public saveObject(value: T) {
    this.storageService.setObject(this.getStorageKey(), value);
  }

  /**
   * Obtiene el valor del localstorage.
   * @returns
   */
  public getObjectValue(): T {
    return this.storageService.getObject(this.getStorageKey());
  }

  /**
   * Elimina del localstorage.
   */
  public deleteFromStorage(): void {
    this.storageService.delete(this.getStorageKey());
  }

  /**
   * Revisa si el valor está definido.
   * @returns
   */
  public isSessionValueDefined(): boolean {
    const aux = this.getObjectValue();
    return aux !== null && aux !== undefined;
  }

  /**
   * getStorageKey().
   */
  protected abstract getStorageKey(): string;
}
