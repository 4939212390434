import { Component, OnInit } from '@angular/core';

/**
 * Componente "Experimenta".
 */
@Component({
  selector: 'app-experiment',
  templateUrl: './experiment.component.html',
  styleUrl: './experiment.component.scss'
})
export class ExperimentComponent implements OnInit {

  /**
   * Constructor
   */
  constructor() { }

  /**
   * @ignore
   */
  ngOnInit(): void { }
}
