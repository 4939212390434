<div class="faqs-header">
  <button class="btn" (click)="goBack()">
    <img class="arrow-left" src="/assets/images/faqs/arrow.svg" alt="Arrow icon">
    <div [innerHTML]="'faqs_Data2' | i18n"></div>
  </button>
  <h1 class="billion-orange" [innerHTML]="'faqs_Data1' | i18n: translationModes.REMOVE_TAGS"></h1>
</div>
<div class="faqs-container">
  <div class="faq" *ngFor="let faq of faqList; index as i">
    <div class="question" (click)="showAnswer(i)">
      <div [innerHTML]="faq?.question | i18n"></div>
      <img class="arrow-down" src="/assets/images/faqs/arrow.svg" alt="Arrow icon" [ngClass]="{'animate': isAnswerShown[i]}">
    </div>
    <div class="answer" [innerHTML]="faq?.answer | i18n" [ngClass]="{'animate': isAnswerShown[i]}"></div>
  </div>
</div>
