import { Component, OnInit } from '@angular/core';
import { TranslationModeEnum } from '../../enums/translation-mode-enum';
import { Location } from '@angular/common';
import { UpdateMetaService } from '../../services/meta-service.service';
import { WindowService } from '../../services/window.service';

/**
 * Componente de términos de cookies.
 */
@Component({
  selector: 'app-tos-cookies',
  templateUrl: './tos-cookies.component.html',
  styleUrl: './tos-cookies.component.scss'
})
export class TosCookiesComponent implements OnInit {
  /**
   * Enumerador con las opciones de traducción.
   */
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;

  /**
   * Constructor.
   * @param location
   * @param updateMetaService
   */
  constructor(
    private location: Location,
    private updateMetaService: UpdateMetaService,
    private windowService: WindowService
  ) { }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.updateMetaService.updateMetaTags('Cookies');
  }

  /**
   * Vuelve a la última ubicación o cierra la pestaña si no hay donde volver.
   */
  goBack(): void {
    if (this.windowService.isBrowser && window.history.length > 1) {
      this.location.back();
    } else {
      window.close();
    }
  }
}
