import { Directive, ElementRef, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { WindowService } from './commons/services/window.service';

/**
 * Directiva lazy loading.
 */
@Directive({
  selector: '[appLazyLoad]',
  standalone: false
})
export class LazyLoadDirective implements OnInit, OnDestroy {
  /**
   * @ignore
   */
  @Output() inViewport = new EventEmitter<void>();

  /**
   * @ignore
   */
  private scrollHandler: () => void;

  /**
   * Constructor.
   * @param el
   * @param windowService
   */
  constructor(
    private el: ElementRef,
    private windowService: WindowService
  ) {
  }

  /**
   * Revisa si el componente está en el viewport al hacer scroll.
   */
  @HostListener('window:scroll')
  onScroll(): void {
    if (this.windowService.isBrowser) {
      const element = this.el.nativeElement;
      const rect = element.getBoundingClientRect();
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

      if (rect.top <= windowHeight && rect.bottom >= 0) {
        this.inViewport.emit();
        this.removeListeners();
      }
    }
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    if (this.windowService.isBrowser) {
      this.scrollHandler = this.onScroll.bind(this);
      window.addEventListener('scroll', this.scrollHandler);
      window.addEventListener('resize', this.scrollHandler);
      this.checkVisibility();
    }
  }

  /**
   * Llama a onScroll().
   */
  private checkVisibility(): void {
    this.onScroll();
  }

  /**
   * Elimina los listeners de los eventos resize y scroll.
   */
  private removeListeners(): void {
    if (this.windowService.isBrowser) {
      window.removeEventListener('scroll', this.scrollHandler);
      window.removeEventListener('resize', this.scrollHandler);
    }
  }

  /**
   * Clean up the event listener when the directive is destroyed
   */
  ngOnDestroy(): void {
    this.removeListeners();
  }
}
