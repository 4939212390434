<nav class="navbar">
  <a href="mailto:info@smartmakings.com">
    <img class="email" src="/assets/images/header/email.svg" alt="Email">
  </a>
  <a href="/" class="home">
    <img class="logo" src="/assets/images/header/logo.svg" alt="Logo">
  </a>
  <a href="https://outlook.office365.com/book/SmartMakings1@SmartMakings.onmicrosoft.com/" target="_blank">
    <img class="calendar" src="/assets/images/header/calendar-btn.svg" alt="Calendar">
  </a>
  <div class="btn-container">
    <a [href]="appUrl">
      <button class="btn teacher" [innerHTML]="'header_Data1' |i18n"></button>
    </a>
    <a [href]="playUrl">
      <button class="btn student" [innerHTML]="'header_Data2' |i18n"></button>
    </a>
  </div>
</nav>
