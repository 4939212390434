import { Component, OnInit } from '@angular/core';

/**
 * Componente "la paradoja del talento".
 */
@Component({
  selector: 'app-paradox',
  templateUrl: './paradox.component.html',
  styleUrl: './paradox.component.scss'
})
export class ParadoxComponent implements OnInit {

  /**
   * Constructor.
   */
  constructor() { }

  /**
   * @ignore
   */
  ngOnInit(): void { }
}
