import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/i18n.service';

/**
 * Pipe para las traducciones i18n.
 */
@Pipe({
  name: 'i18n',
  pure: false,
})
export class I18nPipe implements PipeTransform {
  storeValue: any;
  storeKeyParams!: object;
  result!: string;

  constructor(
    private i18nService: I18nService
  ) { }

  public transform(value: any, ...keyParams: any[]): string {
    this.storeValue = value;
    this.storeKeyParams = keyParams;
    this.result = this.i18nService.getText(value, keyParams);
    return this.result;
  }
}
