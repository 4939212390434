import { Component, OnInit } from '@angular/core';
import { CookiesConfigEnum } from '../../enums/cookies-config-enum';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WindowService } from '../../services/window.service';
import { ResponsiveService } from '../../services/responsive.service';
import { OptionalNcDialogComponent } from './optional-nc-dialog/optional-nc-dialog.component';

/**
 * Componente de términos de cookies
 */
@Component({
  selector: 'app-nc-config',
  templateUrl: './nc-config.component.html',
  styleUrl: './nc-config.component.scss'
})
export class NcConfigComponent implements OnInit {

  /**
   * Enumerador de la configuración de cookies
   */
  cookiesConfigEnum: typeof CookiesConfigEnum;

  /**
   * Constructor
   * @param matDialogRef
   * @param dialog
   * @param windowService
   * @param responsiveService
   */
  constructor(
    private matDialogRef: MatDialogRef<NcConfigComponent>,
    private dialog: MatDialog,
    private windowService: WindowService,
    private responsiveService: ResponsiveService
  ) {
    this.cookiesConfigEnum = CookiesConfigEnum;
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
  }

  /**
   * Abre el dialog para seleccionar cookies personalizadas
   */
  openOptionalCookiesDialog(): void {
    this.dialog.open(OptionalNcDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      maxWidth: '100%',
      panelClass: 'optionalCookiesDialog',
    }).afterClosed().subscribe((response) => {
      this.matDialogRef.close();
    });
    if (!this.responsiveService.isTablet()) {
      this.matDialogRef.close();
    }
  }

  /**
   * Acepta las cookies
   * @param response
   */
  acceptCookies(response: CookiesConfigEnum): void {
    const selectedToggles = this.initializeSelectedToggles(response);
    if (this.windowService.isBrowser) {
      localStorage.setItem('selectedCookiesSM', JSON.stringify(selectedToggles));
    }
    this.matDialogRef.close();
  }

  /**
   * Inicializa solo las esenciales
   * @param response
   * @returns
   */
  private initializeSelectedToggles(response: CookiesConfigEnum): { [key: string]: boolean } {
    const selectedToggles: { [key: string]: boolean } = {
      ESSENTIAL: true,
      ANALYSIS: false,
      FUNCTIONALITY: false,
      MARKETING: false,
      FRAUD: false,
      SECURITY: false
    };

    switch (response) {
      case CookiesConfigEnum.ALL:
        Object.keys(selectedToggles).forEach(key => selectedToggles[key] = true);
        break;
      case CookiesConfigEnum.ESSENTIAL:
        break;
      default:
        break;
    }

    return selectedToggles;
  }
}
