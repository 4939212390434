<div class="school-contact">
  <img class="close" src="/assets/images/contact/close.svg" alt="Close icon" (click)="close()">
  <div class="title" [innerHTML]="'school_contact_form_Data1' | i18n"></div>
  <form class="content" [formGroup]="schoolContactForm" *ngIf="!formSendSuccessfully && !formSendError">
    <div class="input" [ngClass]="{'invalid': schoolContactForm.get('schoolName')?.invalid && submitted}">
      <input id="schoolName" formControlName="schoolName" type="text" required
      [placeholder]="'school_contact_form_Data2' | i18n: translationModes.PLACEHOLDER"/>
      <div class="error" [innerHTML]="'school_contact_form_Data12' | i18n"
        *ngIf="schoolContactForm.get('schoolName')?.invalid && submitted">
      </div>
    </div>
    <div class="input" [ngClass]="{'invalid': schoolContactForm.get('responsiblePerson')?.invalid && submitted}">
      <input id="responsiblePerson" formControlName="responsiblePerson" type="text" required
      [placeholder]="'school_contact_form_Data3' | i18n: translationModes.PLACEHOLDER"/>
      <div class="error" [innerHTML]="'school_contact_form_Data12' | i18n"
        *ngIf="schoolContactForm.get('responsiblePerson')?.invalid && submitted">
      </div>
    </div>
    <div class="input" [ngClass]="{'invalid': schoolContactForm.get('position')?.invalid && submitted}">
      <input id="position" formControlName="position" type="text"
      [placeholder]="'school_contact_form_Data4' | i18n: translationModes.PLACEHOLDER"/>
      <div class="error" [innerHTML]="'school_contact_form_Data12' | i18n"
        *ngIf="schoolContactForm.get('position')?.invalid && submitted">
      </div>
    </div>
    <div class="input" [ngClass]="{'invalid': schoolContactForm.get('email')?.invalid && submitted}">
      <input id="email" formControlName="email" type="email" required
      [placeholder]="'school_contact_form_Data5' | i18n: translationModes.PLACEHOLDER"/>
      <div class="error" [innerHTML]="'school_contact_form_Data13' | i18n"
        *ngIf="schoolContactForm.get('email')?.invalid && submitted">
      </div>
    </div>
    <!-- <div class="input">
      <input id="phone" formControlName="phone" type="text" required
      [placeholder]="'school_contact_form_Data6' | i18n: translationModes.PLACEHOLDER"/>
      <div class="error" [innerHTML]="'school_contact_form_Data14' | i18n"
        *ngIf="schoolContactForm.get('phone')?.invalid && submitted">
      </div>
    </div> -->
    <div class="checkbox tos-check" [ngClass]="{'invalid': schoolContactForm.get('terms')?.invalid && submitted}">
      <input id="terms" formControlName="terms" type="checkbox" required />
      <div class="msg" [innerHTML]="'school_contact_form_Data7' | i18n"></div>
      <div class="error" [innerHTML]="'school_contact_form_Data15' | i18n"
        *ngIf="schoolContactForm.get('terms')?.invalid && submitted">
      </div>
    </div>
    <div class="checkbox">
      <input id="newsletter" formControlName="newsletter" type="checkbox" />
      <div class="msg" [innerHTML]="'school_contact_form_Data8' | i18n"></div>
    </div>
  </form>
  <div *ngIf="formSendSuccessfully" class="thanks" [innerHTML]="'school_contact_form_Data10' |i18n"></div>
  <div *ngIf="formSendError" class="failed" [innerHTML]="'school_contact_form_Data17' |i18n"></div>
  <button class="btn" (click)="onSubmit()"
    [innerHTML]="(formSendError ? 'school_contact_form_Data18' :
    (!formSendSuccessfully ? 'school_contact_form_Data9' : 'school_contact_form_Data11')) |i18n">
  </button>
</div>
