<div class="ally-container">
  <div class="title-container">
    <h2 class="title hset" [innerHTML]="'home_Data36' | i18n"></h2>
    <img class="icon arrow" src="/assets/images/ally/arrow.svg" alt="Arrow icon">
    <img class="icon line" src="/assets/images/ally/line.svg" alt="Line icon">
  </div>
  <div class="content">
    <div class="bg"></div>
    <div class="msg-container">
      <div class="text" [innerHTML]="'home_Data37' | i18n"></div>
      <div class="text" [innerHTML]="'home_Data38' | i18n"></div>
      <div class="text" [innerHTML]="'home_Data39' | i18n"></div>
    </div>
  </div>
  <button #animatedButton class="btn" (click)="openSchoolContact()">
    <span *ngFor="let letter of lettersArray" [innerHTML]="letter"></span>
  </button>
</div>
