<div class="what-container">
  <h2 class="title hft" [innerHTML]="'home_Data14' | i18n"></h2>
  <div class="gallery" #itineraryContainer>
    <div class="itinerary" *ngFor="let itinerary of itineraries" [ngStyle]="{'transform': 'translateX(-' + scrollPosition + 'px)'}">
      <img [src]="itinerary?.iconSrc" class="it-ico" alt="Icon">
      <img [src]="itinerary?.imgSrc" class="it-bg" alt="Background">
      <div class="it-info">
        <div class="it-number" [innerHTML]="'home_Data15' | i18n: translationModes.INSERT_VALUE_AND_REMOVE_TAGS: itinerary?.number"></div>
        <div class="it-sessions" [innerHTML]="'home_Data16' | i18n: translationModes.INSERT_VALUE_AND_REMOVE_TAGS: itinerary?.sessions"></div>
      </div>
      <div class="it-type" [innerHTML]="itinerary?.type | i18n"></div>
      <div class="it-btn" [ngStyle]="{'background-color': itinerary?.color, 'border': '2px solid ' + (itinerary?.border)}" [innerHTML]="'home_Data17' | i18n"></div>
    </div>
  </div>
  <div class="controls">
    <img class="arrow left-arrow" src="/assets/images/what/arrow.svg" alt="Arrow"
    [ngClass]="{'disabled': scrollPosition === 0}" (click)="prev()">
    <img class="arrow right-arrow" src="/assets/images/what/arrow.svg" alt="Arrow"
    [ngClass]="{'disabled': scrollPosition === maxScrollPosition}" (click)="next()">
  </div>
  <img class="icon undo" src="/assets/images/what/icon/2.svg" alt="Undo icon">
  <img class="icon smile" src="/assets/images/what/icon/4.svg" alt="Smile icon">
  <img class="icon dribble" src="/assets/images/what/dribble.svg" alt="Dribble icon">
  <img class="icon rainbow" src="/assets/images/what/rainbow.svg" alt="Rainbow icon">
</div>
