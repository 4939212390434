import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

/**
 * Servicio para comprobar si es navegador o servidor.
 */
@Injectable({
  providedIn: 'root',
})
export class WindowService {
  /**
   * Boolean para saber si es navegador.
   */
  public isBrowser: boolean;

  /**
   * Constructor.
   * @param platformId
   */
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
}
