<div class="partners-container">
  <h2 class="title hnt" [innerHTML]="'home_Data49' | i18n"></h2>
  <div class="slider-container">
    <div class="items-container" #itemsContainer>
      <div class="item" *ngFor="let item of images">
        <img [src]="item">
      </div>
    </div>
  </div>
  <div class="controls">
    <img class="arrow left-arrow" src="/assets/images/what/arrow.svg" alt="Arrow" (mousedown)="prev()">
    <img class="arrow right-arrow" src="/assets/images/what/arrow.svg" alt="Arrow" (mousedown)="next()">
  </div>
  <img class="icon dribble" src="/assets/images/partners/dribble.svg" alt="Dribble icon">
  <img class="icon check" src="/assets/images/partners/check.svg" alt="Check icon">
</div>

<!-- <div class="partners-container">
  <h2 class="title hnt" [innerHTML]="'home_Data49' | i18n"></h2>
  <ng-image-slider class="partners" [images]="showImages" [imagePopup]="false" [infinite]="true" [autoSlide]="1"
    [showArrow]="false" #nav></ng-image-slider>
  <div class="controls">
    <img class="arrow left-arrow" src="/assets/images/what/arrow.svg" alt="Arrow" (mousedown)="prev()">
    <img class="arrow right-arrow" src="/assets/images/what/arrow.svg" alt="Arrow" (mousedown)="next()">
  </div>
  <img class="icon dribble" src="/assets/images/partners/dribble.svg" alt="Dribble icon">
  <img class="icon check" src="/assets/images/partners/check.svg" alt="Check icon">
</div> -->

