import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslationModeEnum } from '../../../commons/enums/translation-mode-enum';
import { I18nService } from '../../../commons/services/i18n.service';
import { WindowService } from '../../../commons/services/window.service';
import { MatDialog } from '@angular/material/dialog';
import { SchoolContactFormComponent } from '../dialogs/school-contact-form/school-contact-form.component';
import { CompanyContactFormComponent } from '../dialogs/company-contact-form/company-contact-form.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

/**
 * Componente "Una oportunidad para empresas y centros educativos".
 */
@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrl: './opportunity.component.scss'
})
export class OpportunityComponent implements OnInit, AfterViewInit {
  /**
   * Contiene la entrada al i18n.
   */
  rightText = 'home_Data48';
  /**
   * Contiene la entrada al i18n.
   */
  leftText = 'home_Data45';
  /**
   * Array con todas las letras del texto
   */
  lettersLeftArray: string[] = [];
  /**
   * Array con todas las letras del texto
   */
  lettersRightArray: string[] = [];
  /**
   * Enumerador con las opciones de traducción.
   */
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;

  /**
   * Controla la animación del botón izquierdo.
   */
  @ViewChild('animatedLeftButton') animatedLeftButton!: ElementRef;
  /**
   * Controla la animación del botón derecho.
   */
  @ViewChild('animatedRightButton') animatedRightButton!: ElementRef;

  /**
   * Constructor.
   * @param windowService
   * @param renderer
   * @param i18nService
   * @param dialog
   */
  constructor(
    private windowService: WindowService,
    private renderer: Renderer2,
    private i18nService: I18nService,
    private dialog: MatDialog,
  ) { }

  /**
   * @ignore
   */
  ngOnInit(): void {
    const rightText = this.i18nService.getText(this.leftText, this.translationModes.REMOVE_TAGS);
    const leftText = this.i18nService.getText(this.rightText, this.translationModes.REMOVE_TAGS);
    this.lettersLeftArray = rightText.split('').map(char => (char === ' ' ? '&nbsp;' : char));
    this.lettersRightArray = leftText.split('').map(char => (char === ' ' ? '&nbsp;' : char));
  }

  /**
   * @ignore
   */
  ngAfterViewInit(): void {
    if (this.windowService.isBrowser) {
      const leftButton = this.animatedLeftButton.nativeElement;
      const rightButton = this.animatedRightButton.nativeElement;
      const leftSpans = leftButton.querySelectorAll('span');
      const rightSpans = rightButton.querySelectorAll('span');
      leftSpans.forEach((span: HTMLElement, index: number) => {
        // const delay = index * 0.01;
        const delay = 0;
        this.renderer.setStyle(span, 'animation-delay', `${delay}s`);
      });
      rightSpans.forEach((span: HTMLElement, index: number) => {
        // const delay = index * 0.01;
        const delay = 0;
        this.renderer.setStyle(span, 'animation-delay', `${delay}s`);
      });
    }
  }

  /**
   * Abre el diálogo de contacto del centro.
   */
  openSchoolContact(): void {
    this.dialog.open(SchoolContactFormComponent, {
      panelClass: 'school-contact-form',
      backdropClass: 'school-contact-backdrop',
      disableClose: true,
      maxWidth: 'none',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  /**
   * Abre el diálogo de contacto de la empresa.
   */
  openCompanyContact(): void {
    this.dialog.open(CompanyContactFormComponent, {
      panelClass: 'company-contact-form',
      backdropClass: 'company-contact-backdrop',
      disableClose: true,
      maxWidth: 'none',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }
}
