<div class="how-container">
  <h2 class="title hst" [innerHTML]="'home_Data28' | i18n"></h2>
  <div class="content">
    <div class="msg-container">
      <div class="text" [innerHTML]="'home_Data30' | i18n"></div>
      <div class="text" [innerHTML]="'home_Data31' | i18n"></div>
      <div class="text" [innerHTML]="'home_Data32' | i18n"></div>
    </div>
    <div class="slides">
      <div class="session" [innerHTML]="'home_Data29' | i18n"></div>
      <div class="slide" [innerHTML]="'home_Data33' | i18n"></div>
      <div class="slide" [innerHTML]="'home_Data34' | i18n"></div>
      <div class="slide" [innerHTML]="'home_Data35' | i18n"></div>
      <img class="icon dribble" src="/assets/images/how/dribble.svg" alt="Dribble icon">
    </div>
  </div>
  <img class="icon check" src="/assets/images/how/check.svg" alt="Check icon">
  <img class="icon smile" src="/assets/images/how/smile.svg" alt="Smile icon">
</div>
