<div class="opportunity-container">
  <h2 class="title het" [innerHTML]="'home_Data41' | i18n"></h2>
  <div class="content">
    <div class="msg" [innerHTML]="'home_Data42' | i18n"></div>
    <div class="dual">
      <div class="card centers">
        <div class="subtitle" [innerHTML]="'home_Data43' | i18n"></div>
        <div class="text" [innerHTML]="'home_Data44' | i18n"></div>
        <button #animatedLeftButton class="btn left" (click)="openSchoolContact()">
          <span *ngFor="let letter of lettersLeftArray" [innerHTML]="letter"></span>
        </button>
      </div>
      <div class="card companies">
        <div class="subtitle" [innerHTML]="'home_Data46' | i18n"></div>
        <div class="text" [innerHTML]="'home_Data47' | i18n"></div>
        <button #animatedRightButton class="btn right" (click)="openCompanyContact()">
          <span *ngFor="let letter of lettersRightArray" [innerHTML]="letter"></span>
        </button>
      </div>
    </div>
  </div>
  <img class="icon dribble" src="/assets/images/opportunity/dribble.svg" alt="Dribble icon">
  <img class="icon undo" src="/assets/images/opportunity/undo.svg" alt="Undo icon">
</div>
