export const HEADER_I18N_ENTRIES = {
  header_Data1: {
    es: '<p>Acceso docentes</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  header_Data2: {
    es: '<p>Acceso estudiantes</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
};
