import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RestClientService } from "./rest-client.service";

/**
 * Servicio para enviar los formularios a la API.
 */
@Injectable({
  providedIn: "root",
})
export class ContactRemoteService {
  /**
   * Variable con la url de la api.
   */
  private URL = "/contact";

  /**
   * Constructor.
   * @param restClientService
   */
  constructor(
    private restClientService: RestClientService
  ) { }

  /**
   * Envia el formulario de contacto a la API.
   * @param request
   * @returns
   */
  sendContactForm(request): Observable<any> {
    return this.restClientService.post(`${this.URL}/form`, request);
  }
}
