import { Component, OnInit } from '@angular/core';
import { TranslationModeEnum } from '../../enums/translation-mode-enum';
import { Location } from '@angular/common';
import { UpdateMetaService } from '../../services/meta-service.service';
import { WindowService } from '../../services/window.service';

/**
 * Componente faqs.
 */
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss'
})
export class FaqsComponent implements OnInit {
  /**
   * Enumerador con las opciones de traducción.
   */
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;
  /**
   * Boolean para mostrar las respuestas.
   */
  isAnswerShown: boolean[] = [];
  /**
   * Variable con el listado de faqs.
   */
  faqList: any[] = [];

  /**
   * Constructor.
   * @param location
   * @param updateMetaService
   */
  constructor(
    private location: Location,
    private updateMetaService: UpdateMetaService,
    private windowService: WindowService
  ) { }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.updateMetaService.updateMetaTags('Faqs');
    this.generateFaqs();
  }

  /**
   * genera automáticamente las 8 preguntas partiendo de faqs_Data3 para la primera question
   * y de faqs_Data4 para la primera answer.
   */
  generateFaqs(): void {
    for (let i = 3; i <= 18; i += 2) {
      this.faqList.push({
        question: `faqs_data${i}`,
        answer: `faqs_data${i + 1}`,
      });
    }
  }

  /**
   * Vuelve a la última ubicación o cierra la pestaña si no hay donde volver.
   */
  goBack(): void {
    if (this.windowService.isBrowser && window.history.length > 1) {
      this.location.back();
    } else {
      window.close();
    }
  }

  /**
   * Muestra la respuesta.
   * @param index
   */
  showAnswer(index: number): void {
    this.isAnswerShown[index] = !this.isAnswerShown[index];
  }
}
