import { Injectable } from '@angular/core';
import { WindowService } from '../window.service';
/**
 * Servicio que obtiene almacena la informacion de los servicio
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {

  /**
   * Constructor
   * @param windowService
   */
  constructor(
    private windowService: WindowService
  ) { }

  /**
   * Asigna a una local un valo
   * @param {string} key Clave en la que se queire guardar
   * @param {string} value El valor que se quiere guardar
   */
  public set(key: string, value: string): void {
    if (this.windowService.isBrowser) {
      window.localStorage[key] = value;
    }
  }
  /**
   * Obtiene el valor de local de navegador
   * @param {string} key
   * @param {string} defaultValue
   * @returns Retorna la información obtenida del dato
   */
  public get(key: string, defaultValue?: string): string {
    if (this.windowService.isBrowser) {
      return window.localStorage[key] || defaultValue || '';
    }
    return defaultValue || '';
  }
  /**
   * Almacena un objeto en el local de windows
   * @param {string} key Contiene la clave que se quiere guardar
   * @param {string} value Contiene el valor que se quiere guardar
   */
  public setObject(key: string, value: any): void {
    if (this.windowService.isBrowser) {
      window.localStorage[key] = JSON.stringify(value);
    }
  }
  /**
   * Obtiene un objeto de local del navegador
   * @param {string} key La clave de los datos que se quiere guardar
   * @returns Retorna la información obtenida del dato
   */
  public getObject(key: string): any {
    if (this.windowService.isBrowser) {
      return JSON.parse(window.localStorage[key] || null);
    }
    return null;
  }
  /**
   * Borra los datos del local de navegador
   * @param {string} key Guarda el dato del navegador que se quiere borrar
   */
  public delete(key: string): any {
    if (this.windowService.isBrowser) {
      delete window.localStorage[key];
    }
  }
}
