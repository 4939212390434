import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { BaseStorageService } from './base-storage.service';
import { UserToken } from '../../models/user-token';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

/**
 * Servicio para gestionar el token del usuario.
 */
@Injectable({
  providedIn: 'root',
})
export class SecurityTokenStorage<T> extends BaseStorageService<T> {
  /**
   * Variable para la sesión expirada.
   */
  private OnSessionExpired = new Subject<UserToken>();

  /**
   * Constructor
   * @param storageService
   */
  constructor(
    storageService: StorageService
  ) {
    super(storageService);
  }

  /**
   * Revisa si la sesión ha expirado.
   * @returns
   */
  public onSessionExpired(): Observable<any> {
    return this.OnSessionExpired;
  }

  /**
   * Devuelve la clave del localstorage.
   * @returns
   */
  protected getStorageKey(): string {
    return 'smartmakings';
  }

  /**
   * Gestiona el login correcto.
   * @returns
   */
  public getAcceptedLogin(): T {
    return super.getObjectValue();
  }
}
