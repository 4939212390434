export enum CookiesConfigEnum {
  DEFAULT,
  ALL,
  ESSENTIAL,
  CUSTOM,
  ANALYSIS,
  FUNCTIONALITY,
  MARKETING,
  FRAUD,
  SECURITY
}
