export const META_CONFIG_I18N_ENTRIES = {
  meta_config_Data1: {
    es: '<p>index, follow</p>',
    en: '<p>index, follow</p>',
    pt: '<p>index, follow</p>',
    fr: '<p>index, follow</p>',
  },

  meta_config_Data2: {
    es: '<p>noindex, follow</p>',
    en: '<p>noindex, follow</p>',
    pt: '<p>noindex, follow</p>',
    fr: '<p>noindex, follow</p>',
  },

  meta_config_Data3: {
    es: '<p>index, nofollow</p>',
    en: '<p>index, nofollow</p>',
    pt: '<p>index, nofollow</p>',
    fr: '<p>index, nofollow</p>',
  },

  meta_config_Data4: {
    es: '<p>noindex, nofollow</p>',
    en: '<p>noindex, nofollow</p>',
    pt: '<p>noindex, nofollow</p>',
    fr: '<p>noindex, nofollow</p>',
  },

  meta_config_Data5: {
    es: '<p>(Smart)Makings | Educación Innovadora</p>',
    en: '<p>(Smart)Makings | Educación Innovadora</p>',
    pt: '<p>(Smart)Makings | Educación Innovadora</p>',
    fr: '<p>(Smart)Makings | Educación Innovadora</p>',
  },

  meta_config_Data6: {
    es: '<p>Nuestra plataforma educativa desarrolla habilidades y competencias esenciales a través de experiencias interactivas.</p>',
    en: '<p>Nuestra plataforma educativa desarrolla habilidades y competencias esenciales a través de experiencias interactivas.</p>',
    pt: '<p>Nuestra plataforma educativa desarrolla habilidades y competencias esenciales a través de experiencias interactivas.</p>',
    fr: '<p>Nuestra plataforma educativa desarrolla habilidades y competencias esenciales a través de experiencias interactivas.</p>',
  },
};
