<div class="privacy-header">
  <button class="btn" (click)="goBack()">
    <img class="arrow-left" src="/assets/images/faqs/arrow.svg" alt="Arrow icon">
    <div [innerHTML]="'privacy_Data2' | i18n"></div>
  </button>
  <h1 class="billion-orange" [innerHTML]="'privacy_Data1' | i18n: translationModes.REMOVE_TAGS"></h1>
</div>
<div class="privacy-container">
  <div [innerHTML]="'privacy_Data3' | i18n: translationModes.INSERT_VALUE_AND_REMOVE_TAGS : '18/11/2024'"></div>
  <div class="m-top" [innerHTML]="'privacy_Data4' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data5' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data6' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data7' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data8' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data9' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data10' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data11' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data12' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data13' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data14' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data15' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data16' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data17' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data18' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data19' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data20' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data21' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data22' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data23' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data24' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data25' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data26' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data27' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data28' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data29' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data30' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data31' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data32' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data33' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data34' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data35' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data36' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data37' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data38' | i18n"></div>
  <div class="separator" [innerHTML]="'privacy_Data39' | i18n"></div>
  <div class="m-top" [innerHTML]="'privacy_Data40' | i18n"></div>
</div>
