import { Injectable } from '@angular/core';
import { WindowService } from './window.service';
/**
 * Servicios de respuestas
 */
@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  /**
   * Constructor que inicialia el onresize del navegador
   */
  constructor(
    private windowService: WindowService
  ) { }

  /**
   * Guardar las llamadas
   * @type {[]}
   */
  private callbacks = [];

  /**
   * Guardar las resoluciones de las pantallas
   * @type {any}
   */
  private breakpoints = {
    tablet: '(min-width: 834px)',
    landscape: '(min-width: 1126px)',
    pc: '(min-width: 1900px)',
  };

  /**
   * Comprueba si se cumple el breakpoint para Tablet.
   */
  public isTablet = () =>
    this.windowService.isBrowser && this.ruleIsMet(this.breakpoints.tablet);

  /**
   * Comprueba si se cumple el breakpoint para landscape.
   */
  public isLandscape = () =>
    this.windowService.isBrowser && this.ruleIsMet(this.breakpoints.landscape);

  /**
   * Comprueba si se cumple el breakpoint para pc.
   */
  public isPC = () =>
    this.windowService.isBrowser && this.ruleIsMet(this.breakpoints.pc);


  /**
   * Comprueba los cambios de las llamadas
   * @param {any} f
   */
  public registerChangeCallback = (f: never) => {
    this.callbacks.push(f);
  };

  /**
   * Comprueba las reglas del navegador
   * @param {string} rule
   * @returns {any}
   */
  // private ruleIsMet = (rule: string) => window.matchMedia(rule).matches;
  private ruleIsMet(rule: string): boolean {
    if (this.windowService.isBrowser) {
      return window.matchMedia(rule).matches;
    }
    return false;
  }
}
