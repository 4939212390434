/**
 * Modelo language.
 */
export class Language {
  /**
   * Constructor
   * @param code //String del código.
   * @param name //String del nombre.
   * @param httpHeader //String de los httpHeader.
   * @param id  // Number con el id.
   * @param generalCode // String o null.
   */
  constructor(
    /**
     * Code.
     */
    public code: string,
    /**
     * Name.
     */
    public name: string,
    /**
     * httpHeader.
     */
    public httpHeader: string,
    /**
     * id.
     */
    public id: number,
    /**
     * generalCode.
     */
    public generalCode: string | null = ''
  ) { }
}
