/**
 * @ignore
 */
export const environment = {
  production: true,
  apiUrl: 'https://api.smartmakings.com',
  appUrl: 'https://app.smartmakings.com',
  playUrl: 'https://play.smartmakings.com',
  sentry: {
    code: '5a4d994e5263609bc3413dbff1cdc746',
    key: 'o4508336457515008',
    domain: 'ingest.de.sentry.io',
    app: '4508336458891344'
  },
};
