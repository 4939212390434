import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { I18nService } from './services/i18n.service';
import { HEADER_I18N_ENTRIES } from './i18n/header-i18n-entries';
import { I18nPipe } from './pipes/i18n.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { LazyLoadDirective } from '../lazy-load.directive';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FOOTER_I18N_ENTRIES } from './i18n/footer-i18n-entries';
import { NcConfigComponent } from './components/nc-config/nc-config.component';
import { OptionalNcDialogComponent } from './components/nc-config/optional-nc-dialog/optional-nc-dialog.component';
import { COOKIES_CONFIG_I18N_ENTRIES } from './i18n/cookies-config-i18n-entries';
import { FaqsComponent } from './components/faqs/faqs.component';
import { FAQS_I18N_ENTRIES } from './i18n/faqs-i18n-entries';
import { LegalComponent } from './components/legal/legal.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TosCookiesComponent } from './components/tos-cookies/tos-cookies.component';
import { LEGAL_I18N_ENTRIES } from './i18n/legal-i18n-entries';
import { PRIVACY_I18N_ENTRIES } from './i18n/privacy-i18n-entries';
import { TOS_COOKIES_I18N_ENTRIES } from './i18n/tos-cookies-i18n-entries';
import { NgImageSliderModule } from 'ng-image-slider';
import { META_CONFIG_I18N_ENTRIES } from './i18n/meta-config-i18n-entries';


@NgModule({
  declarations: [
    I18nPipe,
    LazyLoadDirective,
    HeaderComponent,
    FooterComponent,
    NcConfigComponent,
    OptionalNcDialogComponent,
    FaqsComponent,
    LegalComponent,
    PrivacyComponent,
    TosCookiesComponent,
  ],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatSelectModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgImageSliderModule
  ],
  exports: [
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatSelectModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    LazyLoadDirective,
    HeaderComponent,
    FooterComponent,
    I18nPipe,
    NgImageSliderModule
  ]
})
export class CommonsModule {
  constructor(private i18nService: I18nService) {
    i18nService.addI18nEntriesObject(HEADER_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(FOOTER_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(COOKIES_CONFIG_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(FAQS_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(LEGAL_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(PRIVACY_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(TOS_COOKIES_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(META_CONFIG_I18N_ENTRIES);
  }
}
