<div class="experiment-container">
  <h2 class="title htt" [innerHTML]="'home_Data9' | i18n"></h2>
  <div class="content">
    <div class="text identify" [innerHTML]="'home_Data10' | i18n"></div>
    <div class="text innovate" [innerHTML]="'home_Data11' | i18n"></div>
    <img class="smile" src="/assets/images/experiment/smile.webp" alt="Smiling">
    <div class="text prepare" [innerHTML]="'home_Data12' | i18n"></div>
    <div class="text transform" [innerHTML]="'home_Data13' | i18n"></div>
  </div>
  <img class="icon" src="/assets/images/experiment/rocket.svg" alt="Rocket icon">
</div>
