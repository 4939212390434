export const FOOTER_I18N_ENTRIES = {
  footer_Data1: {
    es: '<p>Contáctanos</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  footer_Data2: {
    es: '<p>Nuestras soluciones educativas no solo benefician a los estudiantes, sino también a las empresas que participan como patrocinadoras. Involúcrate en la educación de la próxima generación y ayuda a cerrar la brecha entre la educación y el mundo laboral.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  footer_Data3: {
    es: '<p>info@smartmakings.com</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  footer_Data4: {
    es: '<p>682 605 166</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  footer_Data5: {
    es: '<p>Edificio Siglo XXI Oficina Norte \n P.º Fluvial, 15, planta 11, 06011 Badajoz</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  footer_Data6: {
    es: '<p>Aviso Legal</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  footer_Data7: {
    es: '<p>Política de Privacidad</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  footer_Data8: {
    es: '<p>Política de Cookies</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  footer_Data9: {
    es: '<p>FAQ’s</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  footer_Data10: {
    es: '<p>Síguenos</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  footer_Data11: {
    es: '<p>© Todos los derechos reservados, (Smart)Makings</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
};
