<div class="home-container">
  <h1 class="title hft" [innerHTML]="'home_Data1' | i18n"></h1>
  <div class="msg" [innerHTML]="'home_Data2' | i18n"></div>
  <img class="notes" src="/assets/images/home/revo.webp" alt="Girl notes">
  <button #animatedButton class="btn join" (click)="goToOpportunity()">
    <span *ngFor="let letter of lettersArray" [innerHTML]="letter"></span>
  </button>
</div>
<app-paradox></app-paradox>
<div class="component" [ngClass]="{'visible': isComponentLoaded(componentsEnum.EXPERIMENT)}" appLazyLoad
  (inViewport)="loadComponent(componentsEnum.EXPERIMENT)">
  <app-experiment *ngIf="isComponentLoaded(componentsEnum.EXPERIMENT)"></app-experiment>
</div>
<div class="component" [ngClass]="{'visible': isComponentLoaded(componentsEnum.WHAT)}" appLazyLoad
  (inViewport)="loadComponent(componentsEnum.WHAT)">
  <app-what *ngIf="isComponentLoaded(componentsEnum.WHAT)"></app-what>
</div>
<div class="component" [ngClass]="{'visible': isComponentLoaded(componentsEnum.HOW)}" appLazyLoad
  (inViewport)="loadComponent(componentsEnum.HOW)">
  <app-how *ngIf="isComponentLoaded(componentsEnum.HOW)"></app-how>
</div>
<div class="component" [ngClass]="{'visible': isComponentLoaded(componentsEnum.ALLY)}" appLazyLoad
  (inViewport)="loadComponent(componentsEnum.ALLY)">
  <app-ally *ngIf="isComponentLoaded(componentsEnum.ALLY)"></app-ally>
</div>
<div class="component" [ngClass]="{'visible': isComponentLoaded(componentsEnum.OPPORTUNITY)}" appLazyLoad
  (inViewport)="loadComponent(componentsEnum.OPPORTUNITY)">
  <app-opportunity *ngIf="isComponentLoaded(componentsEnum.OPPORTUNITY)" id="opportunity"></app-opportunity>
</div>
<div class="component" [ngClass]="{'visible': isComponentLoaded(componentsEnum.PARTNERS)}" appLazyLoad
  (inViewport)="loadComponent(componentsEnum.PARTNERS)">
  <app-partners *ngIf="isComponentLoaded(componentsEnum.PARTNERS)"></app-partners>
</div>
