<footer class="footer">
  <a href="/">
    <img class="logo" src="/assets/images/footer/logo.svg" alt="Logo">
  </a>
  <div class="info">
    <div class="contact">
      <div class="title" [innerHTML]="'footer_Data1' | i18n"></div>
      <div class="msg" [innerHTML]="'footer_Data2' | i18n"></div>
    </div>
    <div class="details">
      <div class="row">
        <img class="icon" src="/assets/images/footer/email.svg" alt="Email">
        <a href="mailto:info@smartmakings.com">
          <div [innerHTML]="'footer_Data3' | i18n"></div>
        </a>
      </div>
      <div class="row">
        <img class="icon" src="/assets/images/footer/phone.svg" alt="Phone">
        <a href="tel:+34682605166">
          <div [innerHTML]="'footer_Data4' | i18n"></div>
        </a>
      </div>
      <div class="row">
        <img class="icon" src="/assets/images/footer/location.svg" alt="Location">
        <a href="https://maps.app.goo.gl/duN6g8mGe8QjDGCA9"
        target="_blank">
          <div [innerHTML]="'footer_Data5' | i18n"></div>
        </a>
      </div>
    </div>
    <div class="tos">
      <a href="/legal">
        <div [innerHTML]="'footer_Data6' | i18n"></div>
      </a>
      <div class="bar"></div>
      <a href="/privacy">
        <div [innerHTML]="'footer_Data7' | i18n"></div>
      </a>
      <div class="bar"></div>
      <a href="/cookies">
        <div [innerHTML]="'footer_Data8' | i18n"></div>
      </a>
      <div class="bar"></div>
      <a href="/faqs">
        <div [innerHTML]="'footer_Data9' | i18n"></div>
      </a>
    </div>
    <div class="social">
      <div class="title" [innerHTML]="'footer_Data10' | i18n"></div>
      <div class="social-icons">
        <a href="https://www.instagram.com/smartmakings/" target="_blank">
          <img class="icon" src="/assets/images/footer/ig.svg" alt="Instagram">
        </a>
        <a href="https://es.linkedin.com/company/smartmakings" target="_blank">
          <img class="icon" src="/assets/images/footer/linkedin.svg" alt="Linkedin">
        </a>
      </div>
    </div>
  </div>
  <div class="separator"></div>
  <div class="reserved" [innerHTML]="'footer_Data11' | i18n"></div>
</footer>
