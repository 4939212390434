<div class="nc-header">
  <button class="btn" (click)="goBack()">
    <img class="arrow-left" src="/assets/images/faqs/arrow.svg" alt="Arrow icon">
    <div [innerHTML]="'tos_cookies_Data2' | i18n"></div>
  </button>
  <h1 class="billion-orange" [innerHTML]="'tos_cookies_Data1' | i18n: translationModes.REMOVE_TAGS"></h1>
</div>
<div class="nc-container">
  <div [innerHTML]="'tos_cookies_Data3' | i18n: translationModes.INSERT_VALUE_AND_REMOVE_TAGS : '18/11/2024'"></div>
  <div class="m-top" [innerHTML]="'tos_cookies_Data4' | i18n"></div>
  <div class="separator" [innerHTML]="'tos_cookies_Data5' | i18n"></div>
  <div class="m-top" [innerHTML]="'tos_cookies_Data6' | i18n"></div>
  <div class="separator" [innerHTML]="'tos_cookies_Data7' | i18n"></div>
  <div class="m-top" [innerHTML]="'tos_cookies_Data8' | i18n"></div>
  <div class="separator" [innerHTML]="'tos_cookies_Data9' | i18n"></div>
  <div class="m-top" [innerHTML]="'tos_cookies_Data10' | i18n"></div>
  <table class="table">
    <thead>
      <tr>
        <th [innerHTML]="'tos_cookies_Data51' | i18n"></th>
        <th [innerHTML]="'tos_cookies_Data52' | i18n"></th>
        <th [innerHTML]="'tos_cookies_Data53' | i18n"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td [innerHTML]="'tos_cookies_Data11' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 720"></td>
        <td [innerHTML]="'tos_cookies_Data13' | i18n"></td>
      </tr>
      <tr>
        <td class="nowrap" [innerHTML]="'tos_cookies_Data14' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 720"></td>
        <td [innerHTML]="'tos_cookies_Data15' | i18n"></td>
      </tr>
      <tr>
        <td [innerHTML]="'tos_cookies_Data16' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 720"></td>
        <td [innerHTML]="'tos_cookies_Data17' | i18n"></td>
      </tr>
    </tbody>
  </table>
  <div class="m-top" [innerHTML]="'tos_cookies_Data18' | i18n"></div>
  <table class="table">
    <thead>
      <tr>
        <th [innerHTML]="'tos_cookies_Data51' | i18n"></th>
        <th [innerHTML]="'tos_cookies_Data52' | i18n"></th>
        <th [innerHTML]="'tos_cookies_Data53' | i18n"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td [innerHTML]="'tos_cookies_Data19' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 720"></td>
        <td [innerHTML]="'tos_cookies_Data20' | i18n"></td>
      </tr>
      <tr>
        <td [innerHTML]="'tos_cookies_Data21' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data22' | i18n: translationModes.INSERT_VALUE : 10"></td>
        <td [innerHTML]="'tos_cookies_Data23' | i18n"></td>
      </tr>
      <tr>
        <td [innerHTML]="'tos_cookies_Data24' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data22' | i18n: translationModes.INSERT_VALUE : 30"></td>
        <td [innerHTML]="'tos_cookies_Data25' | i18n"></td>
      </tr>
      <tr>
        <td [innerHTML]="'tos_cookies_Data26' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 180"></td>
        <td [innerHTML]="'tos_cookies_Data27' | i18n"></td>
      </tr>
      <tr>
        <td [innerHTML]="'tos_cookies_Data28' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 720"></td>
        <td [innerHTML]="'tos_cookies_Data29' | i18n"></td>
      </tr>
      <tr>
        <td [innerHTML]="'tos_cookies_Data30' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 540"></td>
        <td [innerHTML]="'tos_cookies_Data31' | i18n"></td>
      </tr>
      <tr>
        <td [innerHTML]="'tos_cookies_Data32' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 540"></td>
        <td [innerHTML]="'tos_cookies_Data33' | i18n"></td>
      </tr>
      <tr>
        <td [innerHTML]="'tos_cookies_Data34' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 90"></td>
        <td [innerHTML]="'tos_cookies_Data35' | i18n"></td>
      </tr>
      <tr>
        <td [innerHTML]="'tos_cookies_Data36' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 90"></td>
        <td [innerHTML]="'tos_cookies_Data37' | i18n"></td>
      </tr>
      <tr>
        <td [innerHTML]="'tos_cookies_Data38' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 360"></td>
        <td [innerHTML]="'tos_cookies_Data39' | i18n"></td>
      </tr>
      <tr>
        <td [innerHTML]="'tos_cookies_Data40' | i18n"></td>
        <td [innerHTML]="'tos_cookies_Data12' | i18n: translationModes.INSERT_VALUE : 1"></td>
        <td [innerHTML]="'tos_cookies_Data41' | i18n"></td>
      </tr>
    </tbody>
  </table>
  <div class="m-top" [innerHTML]="'tos_cookies_Data42' | i18n"></div>
  <div class="separator" [innerHTML]="'tos_cookies_Data43' | i18n"></div>
  <div class="m-top" [innerHTML]="'tos_cookies_Data44' | i18n"></div>
  <div class="separator" [innerHTML]="'tos_cookies_Data45' | i18n"></div>
  <div class="m-top" [innerHTML]="'tos_cookies_Data46' | i18n"></div>
  <div class="separator" [innerHTML]="'tos_cookies_Data47' | i18n"></div>
  <div class="m-top" [innerHTML]="'tos_cookies_Data48' | i18n"></div>
  <div class="separator" [innerHTML]="'tos_cookies_Data49' | i18n"></div>
  <div class="m-top" [innerHTML]="'tos_cookies_Data50' | i18n"></div>
</div>
