/**
 * Configuración de los metadatos según pantalla.
 */
export const MetaConfig = {
  Faqs: {
    title: 'meta_config_Data5',
    description: 'meta_config_Data6',
    robots: 'meta_config_Data2',
  },
  Legal: {
    title: 'meta_config_Data5',
    description: 'meta_config_Data6',
    robots: 'meta_config_Data2',
  },
  Cookies: {
    title: 'meta_config_Data5',
    description: 'meta_config_Data6',
    robots: 'meta_config_Data2',
  },
  Privacy: {
    title: 'meta_config_Data5',
    description: 'meta_config_Data6',
    robots: 'meta_config_Data2',
  },
};
