<div class="legal-header">
  <button class="btn" (click)="goBack()">
    <img class="arrow-left" src="/assets/images/faqs/arrow.svg" alt="Arrow icon">
    <div [innerHTML]="'legal_Data2' | i18n"></div>
  </button>
  <h1 class="billion-orange" [innerHTML]="'legal_Data1' | i18n: translationModes.REMOVE_TAGS"></h1>
</div>
<div class="legal-container">
  <div [innerHTML]="'legal_Data3' | i18n: translationModes.INSERT_VALUE_AND_REMOVE_TAGS : '18/11/2024'"></div>
  <div class="m-top" [innerHTML]="'legal_Data4' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data5' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data6' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data7' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data8' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data9' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data10' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data11' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data12' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data13' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data14' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data15' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data16' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data17' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data18' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data19' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data20' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data21' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data22' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data23' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data24' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data25' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data26' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data27' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data28' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data29' | i18n"></div>
  <div class="separator" [innerHTML]="'legal_Data30' | i18n"></div>
  <div class="m-top" [innerHTML]="'legal_Data31' | i18n"></div>
</div>
